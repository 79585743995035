import { Controller } from "stimulus"

export default class extends Controller {
  static values = { type: String }

  connect() {
    this.handleTransaction();
  }

  handleTransaction() {
    window.addEventListener('message', event => {
      const subscription_id = event.data.subscription_id
      const payment_id = event.data.payment_id
      const payment_amount = event.data.amount
      let spinner = document.getElementById("spinner");
      // Verificar el mensaje recibido a partir del JSON del pago
      if (event.data.hasOwnProperty('status')) {
        spinner.classList.remove("hidden");
        if (event.data.payment_success) {
          if (window.location.href.includes("add_payment_method")) {
            const newUrl = `${window.location.origin}/musician_profile/my_tmob_subscription`
            window.location.href = newUrl
          } else {
            const item_id = event.data.item.id
            const type = this.typeValue
            const payment_status = event.data.status
            const newUrl = `${window.location.origin}/success_creation?type=${type}&payment_id=${payment_id}&payment_amount=${payment_amount}&payment_status=${payment_status}&item_id=${item_id}&subscription_id=${subscription_id}`
            window.location.href = newUrl
          }
        } else {
          spinner.classList.add("hidden");
          const modalController = this.application.getControllerForElementAndIdentifier(document.getElementById("js-appmodal"),"modal-front");
          modalController.toggleModal()
        }
      }
    });
  }
}
